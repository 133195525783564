

























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { IListItem } from "@/types/types";

@Component({
  name: "ListItems"
})
export default class ListItem extends Vue {
  @Prop({ required: true, default: "" }) item!: IListItem;
  @Prop({ default: false }) isActive!: boolean;
  @Prop({ default: "label" }) label!: string;

  @Emit("toggleDrawer")
  toggleDrawer(): boolean {
    return true;
  }

  @Emit("selectedItem")
  isItemSelected(indx: IListItem): IListItem {
    return indx;
  }
}
